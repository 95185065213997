import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import Schlafgut from "../../images/schlafgut.jpeg";
import bild1 from "../../images/apps/baby/auswahl.png";
import bild2 from "../../images/apps/baby/impressum.png";
import bild3 from "../../images/apps/baby/pdf_screen.png";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Partnerschaft / APP / Babyschlaf-Protokoll" />
      <h1>Babyschlaf-Protokoll APP </h1>
      <h2>Partnerschaft</h2>
      <h4>Du bist Schlafberter:in?</h4>
      <p>
        <b>Du berätst zum Thema Baby- und Kinderschlaf.</b> Dazu lässt du deine
        Klienten die Schlafprotokolle noch <b>analog</b> ausfüllen.
      </p>
      <p>
        Diese müssen dann alle Blätter <i>einscannen</i> oder{" "}
        <i>abfotografieren</i> und dir zuschicken?
      </p>
      <p>
        Das möchtest du ändern? Mit meiner APP können deine Klienten ganz
        einfach alle relevanten Daten zum Babyschlaf und zum Tagesablauf{" "}
        <b>digital</b> erfassen. Das Protokoll kann anschließend ganz{" "}
        <b>bequem als PDF</b> geteilt werden.
      </p>
      <p>Du möchtest dabei sein?</p>
      <p>
        Das ist kein Problem, melde dich bei mir unter{" "}
        <a href="mailto:babyschlaf-protokoll-app-partnerschaft@palmomedia.de">
          babyschlaf-protokoll-app-partnerschaft@palmomedia.de
        </a>{" "}
        und ich trage dich gerne als Schlafberater:in in die APP ein.
      </p>
      <h3>Dein Vorteil:</h3>
      Dein Logo auf dem fertigen Schlafprotokoll
      <a href={bild3} target="_blank">
        <img
          src={bild3}
          style={{
            width: "100%",
            margin: 10,
          }}
          alt="Bild3"
        />
      </a>
      <div className="row">
        <div className="col-sm-6" style={{ padding: 5 }}>
          <a href={bild1} target="_blank">
            <img
              src={bild1}
              style={{
                width: 350,
                margin: 10,
              }}
              alt="Bild1"
            />
          </a>
          <br />
          <center>
            Dein Name als Schlafberater:in hier in der Auswahlliste
          </center>
        </div>
        <div className="col-sm-6 text-right" style={{ padding: 5 }}>
          <center>Deine Kontaktdaten in der APP</center>
          <a href={bild2} target="_blank">
            <img
              src={bild2}
              style={{
                width: 350,
                margin: 10,
              }}
              alt="Bild2"
            />
          </a>
        </div>
      </div>
      <hr />
      <h4>Die App (iOS, Android)</h4>
      <div className="row">
        <div className="col-sm-12" style={{ padding: 5 }}>
          <img
            src="https://michaelpalmer.de/sandy/app/babyschlaf-protokoll-app-logo-512.png"
            style={{
              width: 50,
              margin: 10,
            }}
            className="imagebordered"
            width="50"
            alt="Schlafgut Schlafberatung Schlafprotokoll"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://play.google.com/store/apps/details?id=de.schlafgut_schlafberatung"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Google Play Store Android App"
          >
            <img
              src="https://michaelpalmer.de/sandy/app/store_android.png"
              height="40"
              alt="Google Play Store"
              border="0"
            />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://apps.apple.com/app/schlafgut-schlafprotokoll/id1605835781"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Apple App Store iPhone App"
          >
            <img
              src="https://michaelpalmer.de/sandy/app/store_iphone.png"
              height="40"
              alt="Apple App Store iPhone App"
              border="0"
            />
          </a>
        </div>
        <hr />
        <p>
          Die APP für das Schlafprotokoll!
          <br />
          Weitere Infos dazu unter{" "}
          <a href="/apps/babyschlaf-protokoll-app" target="_self">
            palmomedia.de/apps/babyschlaf-protokoll-app
          </a>
          .
        </p>
        <p>
          Interesse? Dann melde dich bei mir!
          <br />
          <a href="mailto:babyschlaf-protokoll-app-partnerschaft@palmomedia.de">
            babyschlaf-protokoll-app-partnerschaft@palmomedia.de
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
